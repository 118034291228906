import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  EditFocusAreaApi,
  GetAllFocusArea,
  PostFocusArea,
  RemoveService,
} from "../../../network/Network";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import FocusModal from "../../../components/DashboardComp/Modal/FocusModal";

function FocusArea(props) {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [title, setTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [focusData, setFocusData] = useState([]);
  const [editData, setEditData] = useState([]);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };


  // == Remove Service ==
  const DeleteServiceHandler = (id, index) => {
    // setRemoveLoading(true);
    // RemoveService(id, Token)
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res?.data?.message);
    //     serviceData?.splice(index, 1);
    //     setServiceData(res?.data?.response?.data?.data);
    //     setRemoveLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setRemoveLoading(false);
    //   });
  };


    // == get Focus Area api ==
    useEffect(() => {
      setSpinLoad(true);
      GetAllFocusArea(currentPage,Token)
        .then((res) => {
          console.log(res);
          setFocusData(res?.data?.response?.data?.data)
          setSpinLoad(false);
          const total = res?.data?.response?.data?.total;
          const limit = res?.data?.response?.data?.per_page;
          setPageCount(Math.ceil(total / limit));
          console.log(Math.ceil(total / limit));
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data?.message);
          setSpinLoad(false);
        });
    }, [ currentPage]);

     //  == Post Focus Area Api ==
  const AddNewFocusArea = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!title) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("title", title);
    PostFocusArea(data, Token)
      .then((res) => {
        console.log(res);
        focusData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setTitle("")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.errors?.title[0]);
        setLoading(false);
        setTitle("")
      });
  };

  //  == Edit Focus Area Api ==
  const EditFocusArea = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    if (!title) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("title", title);
    EditFocusAreaApi(data, Token, id)
      .then((res) => {
        console.log(res);
        setFocusData(res?.data?.response?.data?.data)
         const total = res?.data?.response?.data?.total;
          const limit = res?.data?.response?.data?.per_page;
          setPageCount(Math.ceil(total / limit));
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setTitle("")
      })
      .catch((err) => {
        console.log(err?.response?.data);
        toast.error(err?.response?.data?.errors?.title[0]);
        setLoading(false);
        setTitle("")
      });
  };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Focus Area </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Focus Area");
              setTitle("")
              setEditData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Focus Area
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#6549A5" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Title</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {focusData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.title}</td>
                    

                      <td>
                        <FontAwesomeIcon
                        className="edit"
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Focus Area");
                            setTitle(item?.title)
                            setEditData(item);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() => DeleteServiceHandler(item?.id, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="pagination-container">
            <ReactPaginate
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <FocusModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        title={title}
        setTitle={setTitle}
        pageTitle={pageTitle}
        AddNewFocusArea={AddNewFocusArea}
        loading={loading}
        editData={editData}
        EditFocusArea={EditFocusArea}
      />
    </>
  );
}

export default FocusArea;
