import React, { useState } from "react";
import { test } from "../../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { EditProfileApi } from "../../../network/Network";
import { EditProfileData } from "../../../redux/actions/AuthAction";
import { toast } from "react-toastify";
import { ImageUrl } from "../../../network/ApiUrl";
import {  useNavigate } from "react-router-dom";

function EditProfile() {
  let dispatch = useDispatch();
  const Navigate = useNavigate();
  const token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);

  const [firstName, setFirstName] = useState(userData?.first_name);
  const [lastName, setLastName] = useState(userData?.last_name);
  const [email, setEmail] = useState(userData?.email);
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone_no);
  const [address, setAddress] = useState(userData?.address);

  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  const EditProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("avatar", newFile);
    data.append("phone_no", phoneNumber);
    data.append("address", address);
    console.log(data);
    EditProfileApi(data, token)
      .then((res) => {
        setLoading(false);
        setUploadLoading(false);
        toast.success(res?.data?.message);
        console.log(res?.data?.response?.data);
        dispatch(EditProfileData(res?.data?.response?.data));
        Navigate("/adminpanel/profile");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setUploadLoading(false);
      });
  };

  console.log(userData);
  return (
    <>
      <div className="profile-Container">
        <p className="dashboard-title">Edit Profile</p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profilePicture-container">
              {uploadLoading ? (
                <img src={fileupload} className="image-fluid image-width" />
              ) : (
                <img
                  src={`${ImageUrl}/${
                    fileupload ? fileupload : userData?.profile_picture
                  }`}
                  className="image-fluid image-width"
                />
              )}
            </div>
            <div className="edit-container">
              <FontAwesomeIcon icon={faPen} className="edit-pen-icon" />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                multiple="false"
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "unset", padding: "0px 20px" }}
              onClick={(e) => EditProfile(e)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProfile;
