import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  // GET_ME_SUCCESS,
  // LOGOUT_REQUEST,
  // LOGOUT_SUCCESS,
  // LOGOUT_ERROR,
  LOGOUT,
  USER_TOKEN,
  EDIT_PROFILE,
  NOTIFICATION,
} from "../Types";
import {
  forgotPasswordOtp,
  forgotPasswordApi,
  postSignIn,
  postSignUp,
  setNewPasswordApi,
  forgotPasswordEmailApi,
  PostLogin,
} from "../../network/Network";
import { toast } from "react-toastify";
import { setError } from "../actions/AlertAction";
import { responses, responsesLogin } from "../../constant/ConstantFunction";

export const login =
  (data, cb = () => {}) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: LOGIN_REQUEST });
      PostLogin(data)
        .then((res) => {
          console.log(res);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res?.data?.response?.data?.user,
          });
          dispatch({
            type: USER_TOKEN,
            payload: res?.data?.response?.data?.token,
          });
          dispatch(setError(responsesLogin(res)));
          return resolve(true);
        })
        .catch((err) => {
          console.log("Login", err.response);
          toast.error("Please Enter Correct Email or Password");
          // dispatch(setError(responses(err)));
          // toast.error(err?.response?.data?.payload?.message);
          dispatch({ type: LOGIN_ERROR });
          return reject(false);
        });
    });

export const signUp = (data, cb) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: SIGNUP_REQUEST });
    postSignUp(data)
      .then((res) => {
        console.log(res);
        dispatch(setError(responses(res)));
        dispatch({ type: SIGNUP_SUCCESS, payload: res?.data?.data });

        dispatch(setError("success", "Successfully Signup!!!"));
        return resolve(true);
      })
      .catch((err) => {
        console.log("Error", err.response);
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);
        return reject(false);
      })
      .finally(() => {
        dispatch({ type: SIGNUP_ERROR });
      });
  });
};

export const forgotPassword = (data) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordApi(data)
      .then((res) => {
        dispatch(setError(responses(res)));
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        console.log(res);
        // toast.success("Email has been sent to your email address");
        return resolve(true);
      })
      .catch((err) => {
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: FORGOT_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const forgotPasswordEmail = (data) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordEmailApi(data)
      .then((res) => {
        dispatch(setError(responses(res)));
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        toast.success("Email has been sent to your email address");
        console.log(res);
        return resolve(true);
      })
      .catch((err) => {
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: FORGOT_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const forgotPasswordOtpVerify = (data) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordOtp(data)
      .then((res) => {
        dispatch(setError(responses(res)));
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        console.log(res);

        // toast.success("Email has been sent to your email address");
        return resolve(true);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.payload?.message);
        dispatch(setError(responses(err)));
        dispatch({ type: FORGOT_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const setPassword = (password, resetToken, cb) => (dispatch) => {
  dispatch({ type: SET_PASSWORD_REQUEST });
  setNewPasswordApi({ password }, resetToken)
    .then((res) => {
      toast.success("Your password has been reset");
      dispatch({ type: SET_PASSWORD_SUCCESS });
    })
    .catch((err) => {
      toast.error(err?.response?.data?.payload?.message);
      dispatch({ type: SET_PASSWORD_ERROR });
    });
};

export const Logout = (payload) => {
  return {
    type: LOGOUT,
    payload,
  };
};





export const EditProfileData = (payload) => {
  return {
    type: EDIT_PROFILE,
    payload,
  };
};


export const NotificationApi = (payload) => {
  return {
    type: NOTIFICATION,
    payload,
  };
};



