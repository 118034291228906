import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHome,
  faSignOutAlt,
  faAddressBook,
  faFolderOpen,
  faImage,
 faUser,
faShapes,
} from "@fortawesome/free-solid-svg-icons";
import {  Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout } from "../../../redux/actions/AuthAction";

function SideBar() {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [focus, setFocus] = useState(false);
  const [users, setUsers] = useState(false);
  const [question, setQuestion] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [images, setImages] = useState(false);
  const [values, setValues] = useState(false);
  const [strength, setStrength] = useState(false);
  const [motivators, setMotivators] = useState(false);
  const [logout, setLogout] = useState(false);
  const [loading, setLoading] = useState(false);


  const activeStateHandler = (type) => {
    if (type === "focus") {
      setFocus(true)
      setUsers(false)
      setQuestion(false)
      setFeedback(false)
      setImages(false)
      setValues(false)
      setStrength(false)
      setMotivators(false)
      setLogout(false);
    } else if (type === "users") {
      setFocus(false)
      setUsers(true)
      setQuestion(false)
      setFeedback(false)
      setImages(false)
      setValues(false)
      setStrength(false)
      setMotivators(false)
      setLogout(false);
    } else if (type === "question") {
      setFocus(false)
      setUsers(false)
      setQuestion(true)
      setFeedback(false)
      setImages(false)
      setValues(false)
      setStrength(false)
      setMotivators(false)
      setLogout(false);
    } else if (type === "feedback") {
      setFocus(false)
      setUsers(false)
      setQuestion(false)
      setFeedback(true)
      setImages(false)
      setValues(false)
      setStrength(false)
      setMotivators(false)
      setLogout(false);
    }
    else if (type === "images") {
      setFocus(false)
      setUsers(false)
      setQuestion(false)
      setFeedback(false)
      setImages(true)
      setValues(false)
      setStrength(false)
      setMotivators(false)
      setLogout(false);
    }
    else if (type === "values") {
      setFocus(false)
      setUsers(false)
      setQuestion(false)
      setFeedback(false)
      setImages(false)
      setValues(true)
      setStrength(false)
      setMotivators(false)
      setLogout(false);
    }
    else if (type === "strength") {
      setFocus(false)
      setUsers(false)
      setQuestion(false)
      setFeedback(false)
      setImages(false)
      setValues(false)
      setStrength(true)
      setMotivators(false)
      setLogout(false);
    }
    else if (type === "motivators") {
      setFocus(false)
      setUsers(false)
      setQuestion(false)
      setFeedback(false)
      setImages(false)
      setValues(false)
      setStrength(false)
      setMotivators(true)
      setLogout(false);
    }
    else {
      setFocus(false)
      setUsers(false)
      setQuestion(false)
      setFeedback(false)
      setLogout(true);
    }
  };

  const LogoutHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(Logout());
      window.location.href = "/";
    }, 200);
  };

  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);

  return (
    <>
      <ul className="sidebar-nav">
        <li
          className={type === "focus" || currentUrl[4] === "focusarea" ? "active" : null}
          onClick={() => activeStateHandler(setType("focus"))}
        >
          <Link to="/adminpanel/focusarea">
            <FontAwesomeIcon
              icon={faHome}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
          Focus Area
          </Link>
        </li>
        <li
          className={type === "users" || currentUrl[4] === "users" ? "active" : null}
          onClick={() => activeStateHandler(setType("users"))}
        >
          <Link to="/adminpanel/users">
            <FontAwesomeIcon
              icon={faUser}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
           Users
          </Link>
        </li>
        <li
          className={type === "question" || currentUrl[4] === "question" ? "active" : null}
          onClick={() => activeStateHandler(setType("question"))}
        >
          <Link to="/adminpanel/question">
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
          Questions
          </Link>
        </li>

        {/* <li
          className={type === "feedback" || currentUrl[4] === "feedback" ? "active" : null}
          onClick={() => activeStateHandler(setType("feedback"))}
        >
          <Link to="/adminpanel/feedback">
            <FontAwesomeIcon
              icon={faAddressBook}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
              FeedBack
          </Link>
        </li> */}
        <li
          className={type === "images" || currentUrl[4] === "splashimages" ? "active" : null}
          onClick={() => activeStateHandler(setType("images"))}
        >
          <Link to="/adminpanel/splashimages">
            <FontAwesomeIcon
              icon={faImage}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Images
          </Link>
        </li>
        <li
          className={type === "values" || currentUrl[4] === "types"  ? "active" : null}
          onClick={() => activeStateHandler(setType("values"))}
        >
          <Link to="/adminpanel/types">
            <FontAwesomeIcon
              icon={faShapes}  
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
         Service Types
          </Link>
        </li>
        {/* <li
          className={type === "strength" ? "active" : null}
          onClick={() => activeStateHandler(setType("strength"))}
        >
          <Link to="/adminpanel/strength">
            <FontAwesomeIcon
              icon={faAddressBook}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
        Strength
          </Link>
        </li>
        <li
          className={type === "motivators" ? "active" : null}
          onClick={() => activeStateHandler(setType("motivators"))}
        >
          <Link to="/adminpanel/motivator">
            <FontAwesomeIcon
              icon={faAddressBook}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
          Motivators
          </Link>
        </li> */}
      

  
      
        {/* <li
          className={type === "term" ? "active" : null}
          onClick={() => activeStateHandler(setType("term"))}
        >
          <Link to="/adminpanel/termCondition">
            <FontAwesomeIcon
              icon={faFlag}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Term&Condition
          </Link>
        </li> */}

        <li
          className={type === "logout" ? "active" : null}
          onClick={() => activeStateHandler(setType("logout"))}
        >
          <a href="" onClick={(e) => LogoutHandler(e)}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
           {loading ? "Loading.." : "Logout"} 
          </a>
        </li>
      </ul>
    </>
  );
}

export default SideBar;
