import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreatePassword from "../pages/AuthViews/CreatePassword";
import ForgetPassword from "../pages/AuthViews/ForgetPassword";
import Login from "../pages/AuthViews/Login";
import SignUp from "../pages/AuthViews/SignUp";

function AuthRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          {/* <Route path="signup" element={<SignUp />} />
          <Route path="forgetpassword" element={<ForgetPassword />} />
          <Route path="createpassword" element={<CreatePassword />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AuthRoutes;

