import React, { useState } from "react";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import {  LogoRadiance } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/actions/AuthAction";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const SignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email && !password) {
      setLoading(false);
    toast.error("Please Enter All Fields")
    } else {
      let data = {
        email: email,
        password: password,
        role: "admin",
      };
      console.log(data);
      setTimeout(async () => {
        setLoading(false);
        let x = await dispatch(login(data));
        console.log("response login", x);
        if (x) {
          window.location.href = "/adminpanel/focusarea";
        }
      }, 600);
    }
  };

  return (
    <>
      <section className="singnin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="radianceFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      <img src={LogoRadiance} alt="" />
                    </figure>
                  </div>
                  <h2 className="title pb-3 blueish">Sign In</h2>
                  <div className="textDv pb-2">
                    <h4 className="subtext authText">
                      Welcome back to Radiance
                    </h4>
                  </div>
                  <form>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        onClick={(e) => {
                          SignInHandler(e);
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Sign In "}
                      </button>
                    </div>
                   
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
