import "./App.css";
import "./assets/css/Style.css";
import "./assets/css/Login.css";
import "./assets/css/ForgetPassword.css";
import "./assets/css/CreatePassword.css";
import "./assets/css/DashboardLayout.css";
import "./assets/css/DashboardDesign.css";
import "./assets/css/AdminPanel.css";
import React from "react";
import AuthRoutes from "./routes/AuthRoutes";
import DashboardLayout from "./pages/Dashboard/DashboardLayout/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "./pages/Alert";
import { useSelector } from "react-redux";

function App() {
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  // const isLogin = true
  
  return (
    <>
      {isLogin ? (
        <>
          <DashboardLayout />
          <ToastContainer />
        </>
      ) : (
        <>
          <AuthRoutes />
          <ToastContainer />
        </>
      )}
      <Alert />
    </>
  );
}

export default App;
