import React, { useState } from "react";
import { createBrowserHistory } from "history";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import { useSelector, useDispatch } from "react-redux";

function CreatePassword() {
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const NewPasswordHandler = () => {
    let data = {
      newPassword: newPassword,
      reNewPassword: reNewPassword,
    };
    // console.log(data);
    // dispatch(forgotPassword(data))
    history.push("/login");
  };

  return (
    <>
      <section className="createpassword">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="radianceFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      {/* <img src={blueLogo} alt="" /> */}
                    </figure>
                  </div>
                  <h2 className="title blueish">Forget Password</h2>
                  <div className="textDv">
                    <h4 className="subtext authText">
                      Create You New Password
                    </h4>
                    {/* <!-- <h4 className="bld"><strong>Create a new Password</strong></h4> --> */}
                  </div>
                  <form>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Re-enter New Password"
                        value={reNewPassword}
                        onChange={(e) => setReNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        type="submit"
                        onClick={() => NewPasswordHandler()}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                    <div className="privacyDv">
                      <h5>
                        By Creating Account you are agree to our{" "}
                        <a className="blueish" href="">
                          Terms & Conditions
                        </a>
                      </h5>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreatePassword;
