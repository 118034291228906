import React from "react";
import {
  Route,
  Navigate,
  Outlet,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "../pages/Dashboard/Profile/Profile";
import EditProfile from "../pages/Dashboard/Profile/EditProfile";
import Notification from "../pages/Dashboard/Notification/Notification";
import TermCondition from "../pages/TermCondition/TermCondition";
import FocusArea from "../pages/Dashboard/FocusArea/FocusArea";
import Users from "../pages/Dashboard/Users/Users";
import FeedBackQuestion from "../pages/Dashboard/FeedBack/FeedBackQuestion";
import AllFeedBack from "../pages/Dashboard/FeedBack/AllFeedBack";
import SplashImages from "../pages/Dashboard/SplashImages/SplashImages";
import FeedBackDetails from "../pages/Dashboard/FeedBack/FeedBackDetails";
import UserDetails from "../pages/Dashboard/Users/UserDetails";
import Values from "../pages/Dashboard/TagPages/Values";
import Strength from "../pages/Dashboard/TagPages/Strength";
import Motivator from "../pages/Dashboard/TagPages/Motivator";


function DashboardRoutes() {
  return (
    <>
      <Routes>
        <Route path="adminpanel" element={<ProtectedRoutes />}>
    
        <Route path="focusarea" element={<FocusArea />} />
        <Route path="users" element={<Users />} />
        <Route path="userdetails/:Id" element={<UserDetails />} />
      
        <Route path="question" element={<FeedBackQuestion />} />
        {/* <Route path="feedback" element={<AllFeedBack />} /> */}
        <Route path="splashimages" element={<SplashImages />} />
        {/* <Route path="feedbackdetails/:Id" element={<FeedBackDetails />} /> */}

        <Route path="types" element={<Values />} />
        {/* <Route path="strength" element={<Strength />} />
        <Route path="motivator" element={<Motivator />} /> */}

          <Route path="profile" element={<Profile />} />
          <Route path="editprofile" element={<EditProfile />} />
          <Route path="notification" element={<Notification />} />
         
          {/* <Route path="termCondition" element={<TermCondition />} /> */}

          <Route path="*" element={<Navigate to="/adminpanel/focusarea" />} />
        </Route>
      </Routes>
    </>
  );
}

export default DashboardRoutes;
