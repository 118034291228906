import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  EditFeedBackQuestions,
  GetFeedBackQuestions,
  PostFeedBackQuestion,
  RemoveService,
} from "../../../network/Network";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import QuestionModal from "../../../components/DashboardComp/Modal/QuestionModal";

function FeedBackQuestion(props) {
  const Token = useSelector((state) => state.AuthReducer.token);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [question, setQuestion] = useState("");
  
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [feedBackData, setFeedBackData] = useState([]);
  const [editData, setEditData] = useState([]);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  //  == Post Questions Api ==
  const AddNewQuestion = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!question) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("question", question);
     PostFeedBackQuestion(data, Token)
      .then((res) => {
        console.log(res);
        setFeedBackData(res?.data?.response?.data?.data)
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setQuestion("")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setQuestion("")
      });
  };

  // == Remove Service ==
  const DeleteServiceHandler = (id, index) => {
    // setRemoveLoading(true);
    // RemoveService(id, Token)
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res?.data?.message);
    //     serviceData?.splice(index, 1);
    //     setServiceData(res?.data?.response?.data?.data);
    //     setRemoveLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setRemoveLoading(false);
    //   });
  };

    // == get FeedBack Question api ==
    useEffect(() => {
      setSpinLoad(true);
      GetFeedBackQuestions(currentPage,Token)
        .then((res) => {
          console.log(res);
          setFeedBackData(res?.data?.response?.data?.data)
          setSpinLoad(false);
          const total = res?.data?.response?.data?.total;
          const limit = res?.data?.response?.data?.per_page;
          setPageCount(Math.ceil(total / limit));
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.data?.message);
          setSpinLoad(false);
        });
    }, [ currentPage]);

    // == edit FeedBack Question api ==
    const EditQuestions = async (e, id) => {
      e.preventDefault();
      setLoading(true);
      if (!question) {
        toast.error("Please Enter All Fields");
        setLoading(false);
        return;
      }
      let data = await new FormData();
      data.append("question", question);
      EditFeedBackQuestions(data, Token, id)
        .then((res) => {
          console.log(res);
          setFeedBackData( res?.data?.response?.data?.data)
          toast.success(res?.data?.message);
          setLoading(false);
          setIsOpenModal(false);
          setQuestion("")
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.errors?.title[0]);
          setLoading(false);
          setQuestion("")
        });
    };
  

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">FeedBack Questions </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Questions");
              setQuestion("")
              setEditData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Questions
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#6549A5" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Questions</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {feedBackData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>{item?.question}</td>

                      <td>
                        <FontAwesomeIcon
                        className="edit"
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Questions");
                            setQuestion(item?.question)
                            setEditData(item);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() => DeleteServiceHandler(item?.id, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="pagination-container">
            <ReactPaginate
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <QuestionModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        question={question}
        setQuestion={setQuestion}
        pageTitle={pageTitle}
        AddNewQuestion={AddNewQuestion}
        loading={loading}
        editData={editData}
        EditQuestions={EditQuestions}
      />
    </>
  );
}

export default FeedBackQuestion;
