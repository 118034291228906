import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  GetTermCondition,
  UpdateTermsCondition,
} from "../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function TermCondition() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [allReportData, setAllReportData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  //   const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [aboutLoading, setAboutLoading] = useState(false);

  const [termData, setTermData] = useState([]);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();

  const [aboutTitle, setAboutTitle] = useState();
  const [aboutContent, setAboutContent] = useState();

  useEffect(() => {
    GetTermCondition()
      .then((res) => {
        console.log(res);
        console.log(res?.data?.response?.data[0].title);

        for (var i = 0; i < res?.data?.response?.data.length; i++) {
          if (res?.data?.response?.data[i].slug === "term-condition") {
            setTitle(res.data.response.data[i]?.title);
            setContent(res.data.response.data[i]?.content);
            console.log("first", res.data.response.data[i]);
          }
        }

        for (var i = 0; i < res?.data?.response?.data.length; i++) {
          if (res?.data?.response?.data[i].slug === "about-us") {
            setAboutTitle(res.data.response.data[i]?.title);
            setAboutContent(res.data.response.data[i]?.content);
            console.log("first", res.data.response.data[i]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  }, []);

  const UpdateTermHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!title || !content) {
      toast.error("Please Enter All Fields");
      return;
    }
    let data = {
      title: title,
      description: content,
    };
    let id = 7;
    UpdateTermsCondition(data, id)
      .then((res) => {
        console.log(res);
        for (var i = 0; i < res?.data?.response?.data.length; i++) {
          if (res?.data?.response?.data[i].slug === "term-condition") {
            setTitle(res.data.response.data[i]?.title);
            setContent(res.data.response.data[i]?.content);
            console.log("first", res.data.response.data[i]);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
      });
  };

  const UpdateAboutHandler = (e) => {
    e.preventDefault();
    setAboutLoading(true);
    if (!aboutTitle || !aboutContent) {
      toast.error("Please Enter All Fields");
      return;
    }
    let data = {
      title: aboutTitle,
      description: aboutContent,
    };
    let id = 6;
    UpdateTermsCondition(data, id)
      .then((res) => {
        console.log(res);
        for (var i = 0; i < res?.data?.response?.data.length; i++) {
          if (res?.data?.response?.data[i].slug === "term-condition") {
            setTitle(res.data.response.data[i]?.title);
            setContent(res.data.response.data[i]?.content);
            console.log("first", res.data.response.data[i]);
          }
        }
        for (var i = 0; i < res?.data?.response?.data.length; i++) {
          if (res?.data?.response?.data[i].slug === "about-us") {
            setAboutTitle(res.data.response.data[i]?.title);
            setAboutContent(res.data.response.data[i]?.content);
          }
        }
        setAboutLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setAboutLoading(false);
      });
  };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Setting </p>
        <div className="serviceCard">
          <div className="term">
            <p>Term & Condition</p>
            <div className="mt-2">
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <textarea
                type="text"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                style={{ width: "unset" }}
                disabled={loading}
                onClick={(e) => UpdateTermHandler(e)}
              >
                {loading ? " Loading.." : "Save"}
              </button>
            </div>
          </div>
          <div className="term mt-5">
            <p>About us</p>
            <div className="mt-2">
              <input
                type="text"
                value={aboutTitle}
                onChange={(e) => setAboutTitle(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <textarea
                type="text"
                value={aboutContent}
                onChange={(e) => setAboutContent(e.target.value)}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary mt-3"
                style={{ width: "unset" }}
                disabled={aboutLoading}
                onClick={(e) => UpdateAboutHandler(e)}
              >
                {aboutLoading ? " Loading.." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermCondition;
