import React from "react";
import { LogoRadiance } from "../../../constant";

function AuthBackground() {
  return (
    <>
      <div className="radiance-image">
        <div className="logoDv">
          {/* <figure>
            <a href="">
              <img src={LogoRadiance} alt="" />
            </a>
          </figure> */}
        </div>
      </div>
    </>
  );
}
export default AuthBackground;
