import React from "react";
import { useLocation } from "react-router-dom";
import { ramdomImage } from "../../../constant/ConstantFunction";
import { ImageUrl } from "../../../network/ApiUrl";

function UserDetails() {
  const Location = useLocation();
  console.log(Location);
  const CustomerData = Location?.state;
  return (
    <>
      <div className="driver-Container">
        <p className="dashboard-title">User Details </p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profilePicture-container">
              {CustomerData?.profile_picture == null ? (
                <img
                  src={`${ramdomImage(CustomerData?.first_name)}`}
                  className="image-fluid image-width"
                  alt=""
                />
              ) : (
                <img
                  src={`${ImageUrl}/${CustomerData?.profile_picture}`}
                  className="image-fluid image-width"
                  alt=""
                />
              )}
            </div>
          </div>
          <p className="driver-details">
            {CustomerData?.first_name} {CustomerData?.last_name}
          </p>
          <div className="row">
            <div className="col-md-6">
              <p className="driver-details">
                Email: {""}
                {CustomerData?.email}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Phone Number: {""}
                {CustomerData?.phone_no}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Address: {""}
                {CustomerData?.address}
              </p>
            </div>
            <div className="col-md-6">
              <p className="driver-details">
                Zip Code: {""}
                {CustomerData?.zip}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDetails;
