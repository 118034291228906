import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import ValueModal from "../../../components/DashboardComp/Modal/ValueModal";
import { toast } from "react-toastify";
import {
  GetAllServices,
  PostNewService,
  RemoveService,
  UpdateServiceApi,
} from "../../../network/Network";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";


function Values(props) {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [title, setTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [editData, setEditData] = useState([]);


  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  //  == Post Service Api ==
  const AddNewService = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!serviceType || !title ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("title", title);
    data.append("type", serviceType);
    PostNewService(data, Token)
      .then((res) => {
        console.log(res);
        serviceData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setTitle("")
        setServiceType("")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setTitle("")
        setServiceType("")
      });
  };

  // == get value api ==
  useEffect(() => {
    setSpinLoad(true);
    GetAllServices(currentPage,Token)
      .then((res) => {
        console.log(res);
        setServiceData(res?.data?.response?.data?.data);
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [currentPage]);

  // == Remove Service ==
  const DeleteServiceHandler = (id, index) => {
    // setRemoveLoading(true);
    // RemoveService(id, Token)
    //   .then((res) => {
    //     console.log(res);
    //     toast.success(res?.data?.message);
    //     serviceData?.splice(index, 1);
    //     setServiceData(res?.data?.response?.data?.data);
    //     setRemoveLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setRemoveLoading(false);
    //   });
  };

  //  == Edit Service Api ==
  const EditService = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    if (!title || !serviceType ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("title", title);
    data.append("type", serviceType);

    UpdateServiceApi(data, Token, id)
      .then((res) => {
        console.log(res);
        setServiceData(res?.data?.response?.data?.data);
        setSpinLoad(false);
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setTitle("")
        setServiceType("")
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setTitle("")
        setServiceType("")
      });
  };

  useEffect(() => {
    // setSpinLoad(true);
    // GetServices(Token)
    //   .then((res) => {
    //     console.log(res);
  
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast.error(err?.data?.message);
    //     setSpinLoad(false);
    //   });
  
  }, []);
  
  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">  Types  </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Service Title");
              setTitle("");
              setServiceType("");
              setEditData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Service 
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#6549A5" />
                </div>
              </div>
            </>
          ) : (
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Type</th>
                  <th scope="col">Title</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {serviceData?.map((item, index) => {
                  return (
                        <tr>
                        <th scope="row" key={item?.id}>
                          {index + 1}
                        </th>
                        <td>{item?.type}</td>
                        <td>{item?.title}</td>
  
                        <td>
                          <FontAwesomeIcon
                           className="edit"
                            icon={faPen}
                            onClick={() => {
                              setIsOpenModal(true);
                              setPageTitle("Edit Service Title");
                              setServiceType(item?.type);
                              setTitle(item?.title);            
                              setEditData(item);
                            }}
                          />
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="RemoveBtn"
                            onClick={() => DeleteServiceHandler(item?.id, index)}
                          />
                        </td>
                      </tr>
 
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="pagination-container">
            <ReactPaginate
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <ValueModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        serviceData={serviceData}
        serviceType={serviceType}
        setServiceType={setServiceType}
        title={title}
        setTitle={setTitle}
        pageTitle={pageTitle}
        AddNewService={AddNewService}
        loading={loading}
        editData={editData}
        EditService={EditService}
      />
    </>
  );
}

export default Values;
