import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ValueModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    pageTitle,
    serviceData,
    serviceType,
    setServiceType,
    title,
    setTitle,
    AddNewService,
    loading,
    editData,
    EditService,
  } = props;
  console.log(pageTitle);
  console.log(editData);
  const [uniqueChars , setUniqueChars] = useState([]);

  const onSetUniqueChars = () => {
    let x = [];
    setUniqueChars(x);
    serviceData.map((val) => {
      if(x.find((value)=> value?.type == val?.type ) ){
      }else{
        x.push(val);
      }
    });
    // for (let i=0; i< props.serviceData.length; i++){
    //   console.log("Test in loop",uniqueChars.find((val)=> val == props.serviceData[i]?.type));
      
    //   // if(props.serviceData[i]?.type !== props.serviceData[i+1]?.type){
    //   //   x.push(props.serviceData[i]);
    //   //   console.log(props.serviceData[i]);
    //   // }
    // }
    setUniqueChars(x);
  }

useEffect(() => {
  onSetUniqueChars();
}, [serviceData])


  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width service-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">{pageTitle}</h2>
        <hr />
        <div>
        <select
            className="region"
            onChange={(e) => {
              setServiceType(e.target.value);
            }}
          >
              <option selected>Select Type</option>
              {uniqueChars?.map((item, index) => {
                return (
                  <option value={item?.type} key={item?.id}>
                    {item?.type}
                  </option>
                );
              })}
          </select>
        </div>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
        />
      
       
       
        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            disabled={loading}
            onClick={(e) => {
              pageTitle == "Edit Service Title"
                ? EditService(e, editData?.id)
                : AddNewService(e);
            }}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ValueModal;
