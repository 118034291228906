import {
  CHECK_CODE,
  SETTING,
  USER,
  UPDATE_PAGE,
  SHOW_PAGE,
  // == END
  API,
  FROGOT_PASSWORD,
  RESEST,
  SIGNUP,
  NOTIFICATION,
  // radiance 
  AUTH,
  LOGIN,
  ADMIN,
  SHOW,
  FOCUS,
  STORE,
  FEEDBACK,
  SHOW_ALL_USER,
  SERVICE,
  PAGE,
  UPDATE,
  SHOW_SERVICE,
  BANNER,
  DELETE,
  SHOW_USER_FEEDBACK,
} from "../network/Endpoint";

import {
  doGet,
  doPost,
  doPatch,
  doDelete,
  doPostProfilePictureUpload,
} from "./Config";


// ${"limit"}=${limit}

export const postSignIn = (data) => {
  return doPost(`${AUTH}${LOGIN}`, { ...data });
};

export const RemoveService = (id, token) => {
  return doGet(`${ADMIN}${SERVICE}${DELETE}/${id}`, token);
};

export const EditProfileApi = (data, token) => {
  return doPost(`${USER}${UPDATE}`, data, token);
};



export const UpdateTermsCondition = (data, id) => {
  return doPost(`${SETTING}${UPDATE_PAGE}/${id}`, data);
};

export const GetTermCondition = () => {
  return doGet(`${SETTING}${SHOW_PAGE}`);
};

// == END ==

export const forgotPasswordOtp = (data) => {
  return doPost(`${AUTH}${CHECK_CODE}`, data);
};

export const postSignUp = (data) => {
  return doPost(`${API}${AUTH}${SIGNUP}`, data);
};

export const forgotPasswordEmailApi = (data) => {
  return doPost(`${API}${AUTH}${RESEST}`, data);
};

export const forgotPasswordApi = (data) => {
  return doPost(`${API}${AUTH}${FROGOT_PASSWORD}`, data);
};

export const setNewPasswordApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};
export const logoutApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};



export const GetAllNotifications = (token) => {
  return doGet(`${API}${NOTIFICATION}`, token);
};




// RADIANCE 
export const PostLogin = (data) => {
  return doPost(`${AUTH}${LOGIN}`, { ...data });
};

export const GetAllFocusArea = (currentPage,token) => {
  return doGet(`${ADMIN}${FOCUS}${SHOW}${PAGE}=${currentPage}`,token);
};

export const PostFocusArea = (data,token) => {
  return doPost(`${ADMIN}${FOCUS}${STORE}`,data,token);
};

export const EditFocusAreaApi = (data, token, Id) => {
  return doPost(`${ADMIN}${FOCUS}${UPDATE}/${Id}`, data, token);
};

export const GetFeedBackQuestions = (currentPage,token) => {
  return doGet(`${ADMIN}${FEEDBACK}${SHOW}${PAGE}=${currentPage}`,token);
};

export const EditFeedBackQuestions = (data, token, Id) => {
  return doPost(`${ADMIN}${FEEDBACK}${UPDATE}/${Id}`, data, token);
};

export const PostFeedBackQuestion = (data,token) => {
  return doPost(`${ADMIN}${FEEDBACK}${STORE}`,data, token);
};


export const GetAllUsers = (currentPage,token) => {
  return doGet(`${ADMIN}${SHOW_ALL_USER}${PAGE}=${currentPage}`,token);
};

export const GetAllServices = (currentPage,token) => {
  return doGet(`${ADMIN}${SERVICE}${SHOW}${PAGE}=${currentPage}`,token);
};

export const PostNewService = (data,token) => {
  return doPost(`${ADMIN}${SERVICE}${STORE}`,data, token);
};

export const UpdateServiceApi = (data,token,Id) => {
  return doPost(`${ADMIN}${SERVICE}${UPDATE}/${Id}`,data, token);
};

// ---
export const GetServices = (token) => {
  return doGet(`${ADMIN}${SHOW_SERVICE}`,token);
};
// ---

export const GetSplashImages = (currentPage,token) => {
  return doGet(`${ADMIN}${BANNER}${SHOW}${PAGE}=${currentPage}`,token);
};

export const UpdateSplashImageApi = (data,token,Id) => {
  return doPost(`${ADMIN}${BANNER}${UPDATE}/${Id}`,data, token);
};

export const PostSplashImageApi = (data,token) => {
  return doPost(`${ADMIN}${BANNER}${STORE}`,data, token);
};

export const DeleteSplashImages = (Id,token) => {
  return doGet(`${ADMIN}${BANNER}${DELETE}/${Id}`,token);
};


export const GetUserFeedback = (currentPage,token) => {
  return doGet(`${ADMIN}${FEEDBACK}${SHOW_USER_FEEDBACK}${PAGE}=${currentPage}`,token);
};


