import React from "react";
import { LogoRadiance } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ImageUrl } from "../../../network/ApiUrl";
import { ramdomImage } from "../../../constant/ConstantFunction";

function Profile() {
  const Navigate = useNavigate();
  const UserData = useSelector((state) => state.AuthReducer.users);
  return (
    <>
      <div className="profile-Container">
        <p className="dashboard-title">Profile</p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profilePicture-container">
              {UserData?.profile_picture == null ? (
                  <img
                  src={`${ramdomImage(`${UserData?.first_name}`)}`}
                  className="image-fluid image-width"
                  alt=""
                />
              ) : (
                <img
                src={`${ImageUrl}/${UserData?.profile_picture}`}
                alt=""
                className="image-fluid image-width"
              />
              ) }
            
            </div>
          </div>
          {/* <p
            className="edit-profile"
            onClick={() => Navigate("/adminpanel/editprofile")}
          >
            Edit Profile
          </p> */}
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">First Name</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{UserData?.first_name}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Last Name</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{UserData?.last_name}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Gender</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{UserData?.gender}</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Phone Number</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{UserData?.phone_no}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Address</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{UserData?.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={LogoRadiance} className="image-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
