import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  DeleteSplashImages,
  GetSplashImages,
  PostSplashImageApi,
  RemoveService,
  UpdateSplashImageApi,
} from "../../../network/Network";
import { useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import SplashImageModal from "../../../components/DashboardComp/Modal/SplashImageModal";
import { ImageUrl } from "../../../network/ApiUrl";

function SplashImages(props) {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [avatar, setAvatar] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [splashData, setSplashData] = useState([]);
  const [editData, setEditData] = useState([]);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setAvatar(file);
      console.log("media", file);
    };
    reader?.readAsDataURL(file);
  };

  //  == Post splash images Api ==
  const AddSplashImage = async (e) => {
    e.preventDefault();
    setLoading(true);
    if ( !avatar || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("image", avatar);
    data.append("description", description);
    PostSplashImageApi(data, Token)
      .then((res) => {
        console.log(res);
        splashData.splice(
          res?.data?.response?.data.length + 1,
          0,
          res?.data?.response?.data
        );
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setAvatar("");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setAvatar("");
        setDescription("");
      });
  };

  // == get splash Image api ==
  useEffect(() => {
    setSpinLoad(true);
    GetSplashImages(currentPage, Token)
      .then((res) => {
        console.log(res);
        setSplashData(res?.data?.response?.data?.data);
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [ currentPage]);

  //  == Edit splash images Api ==
  const EditSplashImages = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    if ( !avatar || !description) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = await new FormData();
    data.append("image", avatar);
    data.append("description", description);
    UpdateSplashImageApi(data, Token, id)
      .then((res) => {
        console.log(res);
        setSplashData(res?.data?.response?.data?.data);
        toast.success(res?.data?.message);
        setLoading(false);
        setIsOpenModal(false);
        setAvatar("");
        setDescription("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.errors?.image[0]);
        setLoading(false);
        setAvatar("");
        setDescription("");
      });
  };

// == Remove Service ==
  const DeleteSplashImageHandler = (id, index) => {
    setRemoveLoading(true);
    setSpinLoad(true);
    DeleteSplashImages(id, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setSplashData(res?.data?.response?.data?.data);
        setSpinLoad(false);
        setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setRemoveLoading(false);
        setSpinLoad(false);
      });
    };

  return (
    <>
      <div className="customers-Container">
        <p className="dashboard-title">Splash Images </p>
        <div className="serviceCard">
          <div
            className="add-services mb-3"
            onClick={() => {
              setIsOpenModal(true);
              setPageTitle("Add Splash Image");
              setAvatar("");
              setDescription("");
              setEditData("");
            }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Splash Image
          </div>
          {spinLoad ? (
            <>
              <div>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#6549A5" />
                </div>
              </div>
            </>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Image</th>
                  <th scope="col">Description</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {splashData?.map((item, index) => {
                  return (
                    <tr>
                      <th scope="row" key={item?.id}>
                        {index + 1}
                      </th>
                      <td>
                          <div className="splashImage-container">
                            <img src={`${ImageUrl}/${item?.image}`} alt="" className="image-fluid image-width" />  
                          </div>
                        </td>
            
                      <td>{item?.description}</td>

                      <td>
                        <FontAwesomeIcon
                         className="edit"
                          icon={faPen}
                          onClick={() => {
                            setIsOpenModal(true);
                            setPageTitle("Edit Splash Image");
                            setAvatar(item?.image);
                            setDescription(item?.description);
                            setEditData(item);
                          }}
                        />
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="RemoveBtn"
                          onClick={() => DeleteSplashImageHandler(item?.id, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div className="pagination-container">
            <ReactPaginate
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <SplashImageModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        avatar={avatar}
        setAvatar={setAvatar}
        description={description}
        setDescription={setDescription}
        pageTitle={pageTitle}
        handleImageUpload={handleImageUpload}
        AddSplashImage={AddSplashImage}
        loading={loading}
        editData={editData}
        EditSplashImages={EditSplashImages}
      />
    </>
  );
}

export default SplashImages;
