import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function QuestionModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    pageTitle,
    question,
    setQuestion,
    AddNewQuestion,
    loading,
    editData,
    EditQuestions,
  } = props;
  console.log(pageTitle);
  console.log(editData);
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width service-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">{pageTitle}</h2>
        <hr />
       
        <textarea
          className="mt-2 mb-2"
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Question"
        />
        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            disabled={loading}
            onClick={(e) => {
              pageTitle == "Edit Questions"
                ? EditQuestions(e, editData?.id)
                : AddNewQuestion(e);
            }}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default QuestionModal;
